import { Link } from "gatsby";
import * as React from "react";
import HeaderMenu from "./HeaderMenu/HeaderMenu";
import SidebarMenu from "./SidebarMenu/SidebarMenu";
import { Segment, Icon, Container, Sidebar } from "semantic-ui-react";
import "../css/styles.css";
import "../css/responsive.css";
import "../css/semantic.css";
import "prismjs/themes/prism-okaidia.css";
import "prismjs/plugins/line-numbers/prism-line-numbers.css";
import { Provider } from "react-redux";
import { store } from "../store";
import CookieBanner from 'react-cookie-banner';


export const menuItems = [
  { name: "Home", path: "/", exact: true, icon: "home", inverted: true },
  { name: "Blog", path: "/blog/", exact: false, icon: "newspaper" },
  { name: "Impressum", path: "/impressum/", exact: true, icon: "quote right" },
  { name: "Datenschutz", path: "/datenschutz/", exact: true, icon: "spy" },
];

export interface LayoutProps {
  location: {
    pathname: string;
  };
  children: any;
}

const Layout = (props: LayoutProps) => {
  const { pathname } = props.location;
  const isHome = pathname === "/";

  return (
    <Provider store={store}>
      <div className="no-push">
        <CookieBanner
          message="Du akzeptierst Cookies, indem du scrollst. Wenn du keine Cookies magst, verlasse diese Seite jetzt."
          onAccept={() => {}}
          cookie="user_allowed_cookies"
          buttonMessage="OK"
          dismissOnScrollThreshold={45}
        />
      </div>
      <Sidebar.Pushable as={Segment}>

        <SidebarMenu Link={Link} pathname={pathname} items={menuItems} visible={false} />

        <Sidebar.Pusher style={{ minHeight: "100vh" }}>
          {/* Header */}
          {isHome ? null : <HeaderMenu
            Link={Link}
            pathname={pathname}
            items={menuItems}
          />}

          {/* Render children pages */}
          <div>
            {props.children}
          </div>

          {/* Footer */}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Provider>
  );
};

export default Layout;

export const withLayout = <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  class WithLayout extends React.Component<P & LayoutProps> {
    render() {
      return (
        <Layout location={this.props.location}>
          <WrappedComponent {...this.props} />
        </Layout>
      );
    }
  };
